#master-container {
    background-image: url("/images/background-shome-light.jpg");
    background-size: cover;
    flex: 1 0 auto;
}

.navbar {
    text-transform: uppercase;
    font-size: 13px;
}

a.active {
    border: 1px white solid;
}

.nav-link:hover {
    border: 1px white solid;
}

.nav-link {
    border: 1px $dark solid;
}

.nav-item {
    margin-left: 2px;
}

footer {
    background-color: $darkSea;
    flex-shrink: 0;

    .container {
        color: $whiteSmoke;
        text-align: right;
    }
}

.marTop50 {
    margin-top: 50px;
}

.marBottom50 {
    margin-bottom: 50px;
}

#homeMasterDiv {
    background-image: url("/images/background-shome.jpg");
    background-size: cover;

    h2 {
        color: white;
        background-color: rgba($color: #20343f, $alpha: 0.75);
        display: inline-block;
        padding: 35px 20px;
    }
}

#accordionShome .card {
    border: 1px solid $darkSea;

    .card-header {
        background-color: $darkSea;

        .btn-link {
            color: $whiteSmoke;
        }
    }
}

#docsCol {
    text-align: center;
    font-weight: 400;
    p > a {
        margin-top: 30px;
        margin-bottom: 10px;
        display: inline-block;
    }
}

.contactCol {
    h4,
    h5,
    h6 {
        line-height: 155%;
        padding-bottom: 14px;
    }

    a {
        color: #212529;
        text-decoration: underline;
    }
}

.underLine {
    text-decoration: underline;
}

.title {
    font-size: 22px;
    font-weight: 600;
}

.subtitle {
    font-weight: 600;
}
